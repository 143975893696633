.photo-info {
  position: absolute;
  left: 0;
  bottom: 0;
}

.collection-detail-title {
  margin-left: 100px;
}
.gallery {
  margin: 0 auto;
}

img {
  margin-bottom: 10px;
}

.collection-photo::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 40px 0 rgba(0, 0, 0, 0.75);
  transition: all 0.2s ease;
}
.collection-photo:hover::after {
  box-shadow: inset 0 0 40px 10px rgba(0, 0, 0, 0.75);
}

.collection-photo {
  display: block;
  position: relative;
}

.photo-detail-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-detail-info {
  display: flex;
}

.photo-detail-info .photo-detail-left {
  margin-right: auto;
}

.photo-detail-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-detail-user {
  display: flex;
  align-items: center;
}

.photo-detail-user img {
  margin-right: 12px;
}

.photo-detail-avatar {
  border-radius: 50%;
}

.photo-detail-right {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.photo-detail-more {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.photos {
  display: flex;
  justify-content: center;
}

.photo-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 18px;
  align-items: center;
}

.username:hover {
  color: #ddd9d9;
  transition: all 0.2s ease;
}

.photo-info .left {
  display: flex;
}

.right {
  position: relative;
}

.icon-circle {
  font-size: 22px;
  color: #ffffff !important;
  margin-right: 12px;
  border-radius: 24px;
  padding: 6px;
}

.icon-circle:hover,
.icon-download:hover {
  background: #000000;
  transition: all 0.2s ease;
}

.download-container {
  position: relative;
}

.icon-download {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  color: #ffffff !important;
  font-size: 22px;
  border-radius: 24px;
  margin-right: 12px;
  margin-top: 8px;
  cursor: pointer;
}

summary {
  font-size: 22px;
  cursor: pointer;
  font-weight: 600;
  padding: 12px;
  display: inline-block;
}

summary:hover {
  background: #000000;
  transition: all 0.3s ease;
  color: #ffffff;
}

details {
}

details:hover {
}

details:hover summary::-webkit-details-marker:hover {
}

.photo-modal {
}

.photo-modal:hover {
  transform: scale(1.01);
  transition: all 0.5s ease;
}
.photo-detail-more-img {
  position: relative;
}
