.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}

.artCollection {
  text-align: center;
}

.App ::selection {
  background: #fff27f;
  color: #161616;
}


.photos {
  display: flex;
  justify-content: center;
}

.photo-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 18px;
  align-items: center;
}

.photo-info .user-avatar {
  margin-left: 6px;
}

.photo-info .username {
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  margin-left: 6px;
}

.username:hover {
  color: #ddd9d9;
  transition: all 0.2s ease;
}

.gallery {
  margin: 0 auto;
}

.icon-circle {
  font-size: 22px;
  color: #ffffff !important;
  margin-right: 12px;
  border-radius: 24px;
  padding: 6px;
}

.icon-circle:hover,
.icon-download:hover {
  background: #000000;
  transition: all 0.2s ease;
}

.download-container {
  position: relative;
}

.icon-download {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  color: #ffffff !important;
  font-size: 22px;
  border-radius: 24px;
  margin-right: 12px;
  margin-top: 8px;
  cursor: pointer;
}

.photos-container-top {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  padding: 0 128px;
}

.no-results {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 500px) {
  .photos-container-top {
    padding: 0 12px;
  }
}

.tag-cloud {
  max-width:60vw;
}

.photo-list {
  width: 96%;
  list-style: none;
  margin: 20px auto;
  text-align: left;
}

.photo-item {
  display: flex;
  width: 300px;
  margin: 20px 0;
}

.photo-item img {
  display: flex;
  width: 100%;
  border: 0px double rgba(0, 0, 0, 0.12);
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  max-width: 90vw;
  max-height: 80vh;
}

.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
  text-align: center;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}